import { storeToRefs } from 'pinia';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type WindowWithZendesk = Window & typeof globalThis & { zE: any };

// If we have cookie consent load the script
// Check if we have unread messages
// If we do open zendesk chat
// If user has interacted with the chat in session, open it
export default defineComponent({
  name: 'ZendeskScript',
  setup() {
    const router = useRouter();
    const { locale } = useI18n();
    const { hasPreferencesConsent } = useTrackingConsent();
    const { getCustomerZendeskToken } = useCustomerApi();

    const zendeskStore = useZendeskStore();
    const { setUserInteracted, setZendeskVisible } = zendeskStore;
    const { showZendesk, hasUserInteracted } = storeToRefs(zendeskStore);

    const { data: zendeskToken } = getCustomerZendeskToken();

    onMounted(() => {
      const windowWithZenDesk = window as WindowWithZendesk;

      if (typeof windowWithZenDesk !== 'undefined') {
        if (sessionStorage.getItem('ZD-widgetOpen')) {
          setZendeskVisible();
          setUserInteracted();
        }
      }
    });

    watch([showZendesk], () => {
      const windowWithZenDesk = window as WindowWithZendesk;
      if (typeof windowWithZenDesk !== 'undefined' && showZendesk) {
        windowWithZenDesk.zE?.('messenger', 'show');
      }
    });

    router.beforeEach(() => {
      const windowWithZenDesk = window as WindowWithZendesk;

      if (
        typeof windowWithZenDesk !== 'undefined' &&
        !hasUserInteracted.value
      ) {
        setZendeskVisible(false);
        windowWithZenDesk?.zE?.('messenger', 'hide');
      }
    });

    return {
      hasPreferencesConsent,
      zendeskToken,
      showZendesk,
      setUserInteracted,
      locale,
    };
  },
  render() {
    if (!this.hasPreferencesConsent || !this.showZendesk) return null;
    return (
      <script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${this.$t('customer_support_page.zendesk_chat_id')}`}
        onLoad={() => {
          const windowWithZenDesk = window as WindowWithZendesk;
          windowWithZenDesk.zE('messenger:set', 'locale', this.locale);
          windowWithZenDesk.zE('messenger:on', 'open', () =>
            this.setUserInteracted()
          );
          if (this.locale === 'fi') {
            windowWithZenDesk.zE(
              'messenger',
              'loginUser',
              (zendeskCallback: (zendeskToken: string) => void) => {
                if (this.zendeskToken) {
                  zendeskCallback(this.zendeskToken);
                }
              }
            );
          }
        }}
      />
    );
  },
});
