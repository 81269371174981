import LoadingEggs from '@/components/LoadingEggs';
import { getGreenLogo, getLoadingScreenLogo } from '@/utils/logo';
import { defaultLanguage } from '@ruokaboksi/api-client';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'LoadingScreen',
  props: {
    loadingTextKey: {
      default: 'global.loading',
      type: String,
    },
  },
  setup() {
    const { locale: currentLocale, locales, localeProperties } = useI18n();
    const localeCookie = useCookie('locale');

    // Hack to get correct logos for different brands during loading
    if (localeCookie.value && localeCookie.value !== currentLocale.value) {
      currentLocale.value = localeCookie.value;
    } else {
      const { host } = useRequestURL();
      const matchedLocale = locales.value.find(
        (locale) => locale.domain === host && 'domainDefault' in locale
      );
      currentLocale.value = matchedLocale?.code ?? defaultLanguage;
    }

    const logo = computed(() => getGreenLogo(localeProperties.value.brand));
    const loadingLogo = computed(() =>
      getLoadingScreenLogo(localeProperties.value.brand)
    );

    return {
      logo,
      localeCookie,
      loadingLogo,
    };
  },
  render(): VNode {
    return (
      <aside class="min-w-screen z-30 grid min-h-[calc(100vh-6rem)] max-w-72 place-content-center bg-white text-center">
        {this.loadingLogo && <this.loadingLogo class="mx-auto w-8" />}
        <this.logo class="mx-auto mb-4 mt-3 block w-full max-w-72" />
        <LoadingEggs />
      </aside>
    );
  },
});
