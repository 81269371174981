import type { FirebaseAppSettings, FirebaseOptions } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import type { Dependencies, User } from 'firebase/auth';
import {
  GoogleAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
} from 'firebase/auth';

/**
 * Firebase client.
 */
export default defineNuxtPlugin(() => {
  if (process.server) {
    throw new Error('Firebase plugin SHOULD NOT BE CALLED ON SERVER!');
  }
  const loggedInUser = useState<User | null>('LoggedInUser', () => null);
  const route = useRoute();

  /** Retrieve variables from public runtime configuration. */
  const {
    firebaseApiKey: apiKey,
    firebaseAuthDomain: authDomain,
    firebaseProjectId: projectId,
  } = useRuntimeConfig().public;

  const firebaseConfig: FirebaseAppSettings = {
    automaticDataCollectionEnabled: false,
  };

  const firebaseOptions: FirebaseOptions = {
    apiKey,
    authDomain,
    projectId,
  };

  /**
   * Firebase Auth dependencies
   * @link https://firebase.google.com/docs/auth/web/custom-dependencies
   * @link https://firebase.google.com/docs/auth/admin/manage-cookies
   */
  const firebaseAuthDependencies: Dependencies = {
    persistence: [browserLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  };

  /**
   * OAuth providers. List here which oauth providers are in use
   */
  const googleAuthProvider = new GoogleAuthProvider();
  googleAuthProvider.addScope('email');
  googleAuthProvider.addScope('profile');

  const microsoftAuthProvider = new OAuthProvider('microsoft.com');
  microsoftAuthProvider.addScope('email');
  microsoftAuthProvider.addScope('profile');

  const firebaseApp = initializeApp(firebaseOptions, firebaseConfig);
  const firebaseAuth = initializeAuth(firebaseApp, firebaseAuthDependencies);

  firebaseAuth.onAuthStateChanged((user: User | null): void => {
    if (!user && route.name?.toString() === 'sign-out') {
      return window.location.replace('/sign-in');
    }
    firebaseAuth.updateCurrentUser(user);
    loggedInUser.value = user;
  });

  return {
    provide: {
      firebaseApp,
      firebaseAuth,
      googleAuthProvider,
      microsoftAuthProvider,
      loggedInUser,
    },
  };
});
