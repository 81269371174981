import { acceptHMRUpdate, defineStore } from 'pinia';
import type { VNode } from 'vue';

type ModalActions = null | VNode;

type ModalContent = null | VNode;

interface ModalState {
  actions: ModalActions;
  content: ModalContent;
  isVisible: boolean;
  title: string;
}

type ModalUpdate = Required<Pick<ModalState, 'content'>> &
  Partial<Pick<ModalState, 'actions' | 'title'>>;

/** Initial state for modal store. */
const initialModalStore: ModalState = {
  actions: null,
  content: null,
  isVisible: false,
  title: '',
};

/** Store for managing modals. */
const useModalStore = defineStore('ModalStore', {
  actions: {
    /** Sets modal content. */
    setModal(modal: ModalUpdate): void {
      this.actions = modal.actions || null;
      this.content = modal.content || null;
      this.title = modal.title || '';
      this.isVisible = !!modal.content;
    },
    close(): void {
      this.isVisible = false;
      // Give time for animations to run their course
      setTimeout(() => {
        this.$reset();
      }, 300);
    },
  },
  state: (): ModalState => ({ ...initialModalStore }),
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot));
}

export default useModalStore;
