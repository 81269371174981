import IconCancel from '@/assets/icons/icon-cancel.svg?component';
import Button from '@/components/Button';
import type { NoticePropsWithId } from '@/stores';
import type { PropType, VNode } from 'vue';

export type NoticeType = 'warning' | 'caution' | 'neutral' | 'success';

export interface NoticeProps {
  text: string;
  type?: NoticeType;
  timeout?: number;
  dismissable?: boolean;
}

export default defineComponent({
  name: 'Notice',
  props: {
    notice: {
      default: () => {
        return { text: '', id: 'default_id' };
      },
      type: Object as PropType<NoticeProps | NoticePropsWithId>,
    },
  },
  setup(props) {
    const noticeStore = useNoticeStore();
    const text = computed(() => props.notice?.text || '');
    const type = computed(() => props.notice?.type || NOTICE_TYPE_DEFAULT);
    const dismissable = computed(
      () => props.notice?.dismissable || NOTICE_DISMISSABLE_DEFAULT
    );

    function closeNotice(): void {
      if (!('id' in props.notice)) return;
      noticeStore.removeNotice(props.notice.id);
    }

    return {
      text,
      type,
      dismissable,
      closeNotice,
    };
  },
  render(): VNode | null {
    if (!this.notice) return null;
    return (
      <div class="notice" data-testid="notice" data-type={this.type}>
        <div class="message" v-html={this.text} />
        {this.dismissable ? (
          <div class="dismiss">
            <Button onClick={this.closeNotice} title={this.$t('notice.close')}>
              <IconCancel aria-hidden="true" class="icon" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  },
});
