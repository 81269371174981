import LoadingScreen from '@/components/LoadingScreen';
import Modal from '@/components/Modal';
import Notices from '@/components/Notices';
import ProductModal from '@/components/ProductModal';
import ZendeskScript from '@/components/ZendeskScript';
import { IETFLanguageMap, type Language } from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';
import type { VNode } from 'vue';
import {
  ClientOnly,
  NuxtLayout,
  NuxtLoadingIndicator,
  NuxtPage,
} from '#components';
import EvaluateScripts from './components/EvaluateScripts/EvaluateScripts';

export default defineNuxtComponent({
  name: 'App',
  setup() {
    const productModal = useProductModalStore();
    const { locale, localeProperties, t } = useI18n();

    const { isModalVisible: isProductModalVisible } = storeToRefs(productModal);

    useHead({
      htmlAttrs: {
        lang: locale,
      },
      titleTemplate: (titleChunk): string => {
        return titleChunk
          ? `${titleChunk} - ${localeProperties.value.brand}`
          : localeProperties.value.brand;
      },
    });
    useSeoMeta({
      description: () => t('meta.description'),
      ogLocale: () => IETFLanguageMap[locale.value as Language],
      ogUrl: () => t('meta.url'),
      ogType: 'website',
      ogSiteName: () => localeProperties.value.brand,
      // ogImage: '',
      twitterCard: 'summary_large_image',
      twitterSite: () => t('meta.twitter'),
      themeColor: '#548e74',
      msapplicationTileColor: '#548e74',
      viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
    });

    return {
      isProductModalVisible,
    };
  },

  /**
   * The server is allowed to render only the LoadingScreen (and NuxtLoadingIndicator) components.
   *
   * The route middleware requires the client to be loaded and it's not worth rendering anything
   * else before the route middleware has done it's magic.
   *
   * We remove the LoadingScreen once the client has loaded.
   **/

  render(): VNode {
    return (
      <>
        <ClientOnly>
          {{
            default: () => (
              <EvaluateScripts>
                <Notices />
                <NuxtLayout>
                  <NuxtPage />
                </NuxtLayout>
                <Modal />
                {this.isProductModalVisible && <ProductModal />}
                <ZendeskScript />
              </EvaluateScripts>
            ),
            fallback: () => <LoadingScreen />,
          }}
        </ClientOnly>
        <NuxtLoadingIndicator />
      </>
    );
  },
});
