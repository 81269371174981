import type { RecipeInstructions } from '@ruokaboksi/api-client';
import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'IngredientLists',
  props: {
    ingredientLists: {
      default: () => [],
      type: Array as PropType<RecipeInstructions['ingredientLists']>,
    },
  },
  render(): VNode | null {
    if (!this.ingredientLists.length) return null;
    return (
      <div class="ingredient-lists">
        {this.ingredientLists.map((list) => (
          <>
            <h3 class="mt-4 text-base font-semibold first:mt-0">
              {list.title}
            </h3>
            {list.ingredients.map((ingredient) => (
              <dl class="flex flex-row-reverse justify-end text-base">
                <dt class="lowercase">{formatTitle(ingredient.title)}</dt>
                {ingredient.amount ? (
                  <dd class="min-w-[3.5rem]">{ingredient.amount}</dd>
                ) : null}
              </dl>
            ))}
          </>
        ))}
      </div>
    );
  },
});
