import type { CustomerState } from '@ruokaboksi/api-client';
import type { RouteLocation } from 'vue-router';

const ALLOWED_IF_SIGNED_IN = ['/sign-out', '/more/change-password'];

type CustomRouteCondition = (
  to: RouteLocation,
  from: RouteLocation,
  isLoggedIn: boolean,
  customerState: string
) => boolean;

type CustomRouteConditionParameters = Parameters<CustomRouteCondition>;

type RuleConditions = {
  isLoggedIn?: boolean;
  customerStates?: string[] | string;
  customConditions?: CustomRouteCondition[] | CustomRouteCondition;
};

type RuleDefinitions = {
  allowed: string[] | string;
  blocked?: string[] | string;
};

type RouteRule = {
  conditions: RuleConditions;
  definitions: RuleDefinitions;
  fallback: string;
};

/**
 * Basic flow:
 *
 * 1. Rules are checked in order. The first matching rule will be applied. So, place specific rules before general ones.
 * 2. When a matching rule is found, the following happens:
 *   a. Check if the route is in the `blocked` list. If matched, redirect to `fallback`.
 *   b. Check if the route is in the `allowed` list. If matched, grant access.
 *   c. If neither, redirect to `fallback`.
 *
 * Rule structure:
 *
 * - conditions: Specifies the state of the user when the rule should be applied.
 *    - isLoggedIn: Boolean. Whether the user is logged in.
 *    - customerStates: String or Array. Possible states of the customer.
 *        Supports wildcards, e.g., 'normal:*' matches any state starting with 'normal:'.
 *    - customConditions: Custom condition(s) to be evaluated. See `CustomRouteCondition` type for details.
 *
 * - definitions: Defines which routes are allowed or blocked for given conditions.
 *    - blocked: String or Array. Routes that are explicitly blocked.
 *    - allowed: String or Array. Routes that are accessible.
 *        Supports wildcards, e.g., '*' (all routes) or '/onboarding*' (all routes starting with '/onboarding').

 * - fallback: String. Route to redirect to if the `toRoute` is blocked or not allowed.
 *
 * Notes:
 *
 * Since the routes are checked in order, changing the order may drastically change the behaviour.
 */
const ROUTE_RULES: RouteRule[] = [
  {
    conditions: { isLoggedIn: false },
    definitions: {
      allowed: [...USER_REQUIRED_WHITELIST],
    },
    fallback: '/sign-in',
  },
  {
    conditions: { customerStates: ['blocking-problem:*'] },
    definitions: {
      allowed: [
        '/payment-required',
        '/pay-delivery',
        '/more/change-password',
        'sign-out',
      ],
    },
    fallback: '/payment-required',
  },
  // A rule for the typeform/tally flow
  {
    conditions: {
      customerStates: '*',
      customConditions: (_to, from) => {
        const isOrderFromFormService =
          ['typeform', 'tally'].includes(String(from.query.from)) ||
          // An EXTREMELY hacky way to check if the user is coming from a form service. The from query param is originally set by form service, but added to the continueUrl by SignInEmailForm component.
          (from.query.continueUrl &&
            ['typeform', 'tally'].includes(
              String(
                new URL(String(from.query.continueUrl)).searchParams.get('from')
              )
            ));

        if (!isOrderFromFormService) {
          return false;
        }

        // Retrieve the user's creation time
        const { currentUser } = useCurrentUser();
        const creationTime = currentUser.value?.metadata.creationTime;

        // If creation time is not available, return false
        if (!creationTime) {
          return false;
        }

        // Calculate the time difference between now and the user's creation time
        const creationDate = new Date(creationTime);
        const currentDate = new Date();
        const hoursDiff =
          (currentDate.getTime() - creationDate.getTime()) / (1000 * 60 * 60);

        // Check if the user was created more than 24 hours ago
        return hoursDiff > 24;
      },
    },
    definitions: {
      allowed: ['/existing-user*'],
    },
    // Include the from query param in the fallback route
    fallback: '/existing-user?from=typeform',
  },
  {
    conditions: {
      customerStates: ['normal:*', 'temporary-problem:*'],
    },
    definitions: {
      blocked: ['/no-subscription', '/onboarding*'],
      allowed: '*',
    },
    fallback: '/',
  },

  {
    conditions: { customerStates: ['onboarding:no-basic-info'] },
    definitions: {
      allowed: ['/no-subscription'],
    },
    fallback: '/no-subscription',
  },
  {
    conditions: { customerStates: ['onboarding:no-subscription'] },
    definitions: {
      allowed: ['/no-subscription'],
    },
    fallback: '/no-subscription',
  },
  {
    conditions: {
      customerStates: [
        'onboarding:no-billing-option',
        'onboarding:no-billing-option-payment-failed',
      ],
    },
    definitions: {
      allowed: ['/onboarding', '/onboarding/add-payment-method'],
    },
    fallback: '/onboarding',
  },
  {
    conditions: {
      customerStates: ['onboarding:address-check'],
    },
    definitions: {
      allowed: ['/onboarding/confirm-address'],
    },
    fallback: '/onboarding/confirm-address',
  },
  {
    conditions: {
      customerStates: ['onboarding:missing-consent'],
    },
    definitions: {
      allowed: ['/onboarding/settings'],
    },
    fallback: '/onboarding/settings',
  },
];

/**
 * Matches a string or array of strings against a pattern. Supports wildcards.
 */
const matchesPattern = (
  pattern: string | string[] | undefined,
  actual: string
) => {
  // If pattern is undefined, return false immediately
  if (!pattern) return false;

  // Always work with an array for consistency
  const patternsArray = Array.isArray(pattern) ? pattern : [pattern];

  // Check if any pattern in the array matches
  return patternsArray.some((pat) => {
    // Convert wildcard into regex equivalent and handle optional trailing slash
    const regex = new RegExp(`^${pat.split('*').join('.*')}(\\/)?$`);
    return regex.test(actual);
  });
};

/**
 * Evaluates route conditions if they are defined in the rule.
 */
const evaluateCustomRouteConditions = (
  routeConditions: CustomRouteCondition | CustomRouteCondition[] | undefined,
  ...params: CustomRouteConditionParameters
): boolean => {
  if (!routeConditions) {
    return true; // No conditions means always true
  }

  const conditionsArray = Array.isArray(routeConditions)
    ? routeConditions
    : [routeConditions];
  return conditionsArray.every((condition) => condition(...params));
};

/**
 * Returns the first matching rule based on the user state.
 */
const determineApplicableRule = (
  isLoggedIn: boolean,
  state: string,
  to: RouteLocation,
  from: RouteLocation
): RouteRule => {
  for (const rule of ROUTE_RULES) {
    const { isLoggedIn: ruleIsLoggedIn, customerStates } = rule.conditions;

    if (
      !evaluateCustomRouteConditions(
        rule.conditions.customConditions,
        to,
        from,
        isLoggedIn,
        state
      )
    ) {
      continue;
    }

    if (ruleIsLoggedIn !== undefined && ruleIsLoggedIn !== isLoggedIn) {
      continue;
    }

    const stateMatches = matchesPattern(customerStates, state);
    if (stateMatches) {
      return rule;
    }
  }

  return {
    conditions: {},
    definitions: {
      allowed: [...USER_REQUIRED_WHITELIST],
    },
    fallback: '/',
  };
};

/**
 * Evaluates the actual route against the rules to determine if access should be granted or blocked.
 */
const checkRulesAndMaybeApplyFallback = (
  isLoggedIn: boolean,
  state: string,
  to: RouteLocation,
  from: RouteLocation
) => {
  const rule = determineApplicableRule(isLoggedIn, state, to, from);

  // Always check for blocked routes first. A blocked route will always override an allowed route.
  if (matchesPattern(rule.definitions.blocked, to.path)) {
    return navigateTo(rule.fallback);
  }

  if (matchesPattern(rule.definitions.allowed, to.path)) {
    return;
  }

  return navigateTo(rule.fallback);
};

const fetchCustomerState = async (): Promise<CustomerState | null> => {
  const { $queryClient } = useNuxtApp();
  const apiClient = useApiClient();
  const cachedCustomerState = $queryClient?.getQueryData<CustomerState>([
    'myCustomerState',
  ]);

  if (cachedCustomerState) {
    return cachedCustomerState;
  }

  try {
    return await apiClient.customers.retrieveState({ customerId: '_me' });
  } catch (_error) {
    return null;
  }
};

/**
 * Orchestrates the operation of the route guard.
 *
 * Structured for efficiency:
 * 1. Checks globally allowed routes (no external data needed)
 * 2. Confirms user's authentication state and redirects to sign in page if needed (auth state needed)
 * 3. Fetches detailed user data and applies route rules (auth state, customer state needed)
 *
 * This design minimizes unnecessary data fetches and computations.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    return;
  }

  // First, check if the route is globally allowed
  if (USER_REQUIRED_WHITELIST.includes(to.path)) {
    return;
  }

  // Second, check if the user is logged in
  const { $firebaseAuth } = useNuxtApp();
  await $firebaseAuth.authStateReady();

  const isLoggedIn = !!$firebaseAuth.currentUser;
  if (!isLoggedIn) {
    return navigateTo('/sign-in');
  }

  if (ALLOWED_IF_SIGNED_IN.includes(to.path)) {
    return;
  }

  // Third, fetch detailed user data and check route rules
  const customerState = await fetchCustomerState();

  if (customerState) {
    return checkRulesAndMaybeApplyFallback(
      isLoggedIn,
      customerState.state,
      to,
      from
    );
  }
});
